import { useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';
import './HomePage.scss';
import MetaTags from '../MetaTags/MetaTags';
import image from "../../assets/images/classic_blur.jpg";
import image1080 from "../../assets/images/classic_blur-3@0,25x.jpg";
import image600 from "../../assets/images/classic_blur-4@0,1x.jpg";
import Img from '../Img/Img';

function HomePage() {
	const spotlight = useRef();

	const changeSpotlight = (e) => {
		const x = e.pageX;
		const y = e.pageY;
		spotlight.current.style.background = `radial-gradient(circle at ${x}px ${y}px, rgba(255, 255, 255, .01) 2%, rgba(0, 0, 0, 0.2) 10%, rgba(0, 0, 0, 0.9) 25%)`;
	}
	return (
		<>
			<MetaTags />

			<div className="HomePage" onMouseMove={changeSpotlight}>
				<div className="HomePage__logo">
					<h1 className="HomePage__heading">
						<Link to="music">
							<Logo />
						</Link>
					</h1>
					<h2 className="HomePage__sub-heading">musicbysounds</h2>
				</div>
				<div className="HomePage__spotlight" ref={spotlight}></div>
				<Img
					srcset={[
						{ src: image600, width: 600},
						{ src: image1080, width: 1080},
						{ src: image},
					]}
					src={image}
					alt="Blurred image of Anders and Johann" className="HomePage__image"
				/>
			</div>
		</>
	);
}

export default HomePage;
