import { Fragment } from 'react'
import './Button.scss';
import {
	Link
} from "react-router-dom";

function Button({
	link = false,
	url = "",
	newTab = false,
	isPrimary = false,
	isSecondary = false,
	isTertiary = false,
	children
}) {
	return (
		<Fragment>
			{link ?
				<Link to={link} className={`Button ${isPrimary && "primary"} ${isSecondary && "secondary"} ${isTertiary && "tertiary"}`} >
					{children}
				</Link> :
				<a target={newTab ? "_blank" : ""} href={url} className={`Button ${isPrimary && "primary"} ${isSecondary && "secondary"} ${isTertiary && "tertiary"}`} >
					{children}
				</a>
			}
		</Fragment>
	);
  }

  export default Button;
