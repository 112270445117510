import './Logo.scss';
import { ReactComponent as SVG } from'../../assets/images/logo.svg';

function Logo({isBlack = false, classNames = []}) {
	const stringClassNames = classNames.join(" ");
	return (
		<SVG alt="SOUNDS" className={`Logo ${isBlack && "black"} ${stringClassNames}`} />
	);
  }

  export default Logo;
