import './StreamingService.scss';
import Button from "../Button/Button"
import useStore from '../../store';

function StreamingService({slug, url = "", id = null, isVideo = false, isDownload}) {
	const service = useStore(state => state.services.find(service => service.slug === slug));
	const Icon = service.icon;

	return (
		<div id={id} className="StreamingService">
			<div className="StreamingService__brand">
				<div className="StreamingService__icon"><Icon /></div>
				<p className="StreamingService__name">{service.name}</p>
			</div>
			<div className="StreamingService__btn">
				<Button isPrimary={!isVideo} isTertiary={isVideo} url={url} newTab>{isDownload ? "Get" : "Play"}</Button>
			</div>
		</div>
	)
}

export default StreamingService;
