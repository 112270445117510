import './AnimatedText.scss';

function AnimatedText({children}) {
	return (
		<span className="AnimatedText">{
			children.split("").map((letter, key) => <span style={{animationDelay: `${.3 + key * 0.1}s`}}>{letter}</span>)
		}</span>
	)
}

export default AnimatedText;
