import { useEffect, useRef, useState } from 'react';
import Img from '../Img/Img';
import './AboutPageImage.scss';


function AboutPageImage({src, alt, scrollBottom, parallaxRange = [-100, 100], shouldFill = false, scrollContainer = false, zoom = 1, ...props}) {
	const thisEl = useRef(null);
	const [parallaxStateRange, setParallaxStateRange] = useState([]);
	const [offsetTop, setOffsetTop] = useState(null);
	const [absoluteRange, setAbsoluteRange] = useState(null);
	const [pixelsScrolledPerPercent, setPixelsScrolledPerPercent] = useState(null);
	const [parallaxDirection, setParallaxDirection] = useState(null);
	const [scrollPercentage, setScrollPercentage] = useState((scrollBottom - offsetTop) / pixelsScrolledPerPercent); // The number of percentage that the element is scrolled into view

	const setStates = (amount = 1) => {
		const newParallaxRange = [parallaxRange[0] * amount, parallaxRange[1] * amount];
		setParallaxStateRange(newParallaxRange);
		const scrollLength = scrollContainer?.offsetHeight + thisEl.current?.offsetHeight; // The scroll length when an element is in the viewport
		const newAbsoluteRange = Math.abs(newParallaxRange[0] - newParallaxRange[1]);
		setAbsoluteRange(newAbsoluteRange);
		const newPixelsScrolledPerPercent = scrollLength / newAbsoluteRange;
		setPixelsScrolledPerPercent(newPixelsScrolledPerPercent);
		const parallaxDirection = newParallaxRange[0] - newParallaxRange[1] < 0 ? 1 : -1;
		setParallaxDirection(parallaxDirection);
	}

	const updateImageSizes = () => {
		const newOffsetTop = getOffset(thisEl.current).top;
		setOffsetTop(newOffsetTop); // Probably need to be done on resize as well
		setScrollPercentage((scrollBottom - newOffsetTop) / pixelsScrolledPerPercent);

		if (scrollContainer?.offsetWidth < 900)	return setStates(.5);
		return setStates(1);
	}

	useEffect(() => {
		setStates(1);
	}, [])

	useEffect(() => {
		updateImageSizes();

		window.addEventListener("resize", updateImageSizes);

		return () => {
			window.removeEventListener("resize", updateImageSizes);
		}
	}, [scrollContainer]);

	/**
	 * Get an elements offset position on page
	 *
	 * @param   {HTMLElement}  el  HTML element to get position of
	 *
	 * @return  {Object}           Object containing top and left position of element
	 */
	function getOffset(el) {
		var _x = 0;
		var _y = 0;
		while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
			if(el === scrollContainer) break; // break out if parent element is element that has the main scrolling on the page
			_x += el.offsetLeft - el.scrollLeft;
			_y += el.offsetTop - el.scrollTop;
			el = el.offsetParent;
		}
		return { top: _y, left: _x };
	}

	useEffect(() => {
		setOffsetTop(getOffset(thisEl.current).top); // Probably need to be done on resize as well
	}, [scrollContainer]);

	useEffect(() => {
		setScrollPercentage((scrollBottom - offsetTop) / pixelsScrolledPerPercent);
	}, [scrollBottom]);

	return (
		<div className={`AboutPageImage${ shouldFill ? " fill" : ""}`} ref={thisEl}>
			<Img src={ src } alt={ alt } style={{translate: `0 ${parallaxStateRange[0] + scrollPercentage * (parallaxDirection)}px`, height: `calc(100% + ${absoluteRange * zoom}px)`, top: (parallaxStateRange[0]) * (parallaxDirection) + "px"}} {...props} />
		</div>
	);
}

export default AboutPageImage;
