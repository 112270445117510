import create from 'zustand';
import imgYoung1920 from './assets/images/SOUNDS-Young-1@0,5x.jpg';
import imgApex1920 from './assets/images/SOUNDS_Apex_2019-1@0,5x.jpg';
import imgApex2880 from './assets/images/SOUNDS_Apex_2019-2@0,75x.jpg';
import imgApex960 from './assets/images/SOUNDS_Apex_2019-3@0,25x.jpg';
import imgApex384 from './assets/images/SOUNDS_Apex_2019-4@0,1x.jpg';
import imgApex from './assets/images/SOUNDS_Apex_2019.jpg';
import imgBackAround1920 from './assets/images/SOUNDS_Back_Around-1@0,5x.jpg';
import imgBackAround2880 from './assets/images/SOUNDS_Back_Around-2@0,75x.jpg';
import imgBackAround960 from './assets/images/SOUNDS_Back_Around-3@0,25x.jpg';
import imgBackAround384 from './assets/images/SOUNDS_Back_Around-4@0,1x.jpg';
import imgBackAround from './assets/images/SOUNDS_Back_Around.jpg';
import imgDTOLA1920 from './assets/images/SOUNDS_Dont_Throw_Our_Love_Away-1@0,5x.jpg';
import imgDTOLA2880 from './assets/images/SOUNDS_Dont_Throw_Our_Love_Away-2@0,75x.jpg';
import imgDTOLA960 from './assets/images/SOUNDS_Dont_Throw_Our_Love_Away-3@0,25x.jpg';
import imgDTOLA384 from './assets/images/SOUNDS_Dont_Throw_Our_Love_Away-4@0,1x.jpg';
import imgDTOLA from './assets/images/SOUNDS_Dont_Throw_Our_Love_Away.jpg';
import imgDreamingOutLoud1920 from './assets/images/SOUNDS_Dreaming_Out_Loud-1@0,5x.jpg';
import imgDreamingOutLoud2880 from './assets/images/SOUNDS_Dreaming_Out_Loud-2@0,75x.jpg';
import imgDreamingOutLoud960 from './assets/images/SOUNDS_Dreaming_Out_Loud-3@0,25x.jpg';
import imgDreamingOutLoud384 from './assets/images/SOUNDS_Dreaming_Out_Loud-4@0,1x.jpg';
import imgDreamingOutLoud from './assets/images/SOUNDS_Dreaming_Out_Loud.jpg';
import imgOutOfBounds1920 from './assets/images/SOUNDS_Out_Of_Bounds-1@0,5x.jpg';
import imgOutOfBounds2880 from './assets/images/SOUNDS_Out_Of_Bounds-2@0,75x.jpg';
import imgOutOfBounds960 from './assets/images/SOUNDS_Out_Of_Bounds-3@0,25x.jpg';
import imgOutOfBounds384 from './assets/images/SOUNDS_Out_Of_Bounds-4@0,1x.jpg';
import imgOutOfBounds from './assets/images/SOUNDS_Out_Of_Bounds.jpg';
import imgOutOfBoundsRemix1920 from './assets/images/SOUNDS_Out_Of_Bounds_Remix-1@0,5x.jpg';
import imgOutOfBoundsRemix2880 from './assets/images/SOUNDS_Out_Of_Bounds_Remix-2@0,75x.jpg';
import imgOutOfBoundsRemix960 from './assets/images/SOUNDS_Out_Of_Bounds_Remix-3@0,25x.jpg';
import imgOutOfBoundsRemix384 from './assets/images/SOUNDS_Out_Of_Bounds_Remix-4@0,1x.jpg';
import imgOutOfBoundsRemix from './assets/images/SOUNDS_Out_Of_Bounds_Remix.jpg';
import imgWontLetYou1920 from './assets/images/SOUNDS_Wont_Let_You-1@0,5x.jpg';
import imgWontLetYou2880 from './assets/images/SOUNDS_Wont_Let_You-2@0,75x.jpg';
import imgWontLetYou960 from './assets/images/SOUNDS_Wont_Let_You-3@0,25x.jpg';
import imgWontLetYou384 from './assets/images/SOUNDS_Wont_Let_You-4@0,1x.jpg';
import imgWontLetYou from './assets/images/SOUNDS_Wont_Let_You.JPG';
import imgWorkThisOut1920 from './assets/images/SOUNDS_Work_This_Out-1@0,5x.jpg';
import imgWorkThisOut2880 from './assets/images/SOUNDS_Work_This_Out-2@0,75x.jpg';
import imgWorkThisOut960 from './assets/images/SOUNDS_Work_This_Out-3@0,25x.jpg';
import imgWorkThisOut384 from './assets/images/SOUNDS_Work_This_Out-4@0,1x.jpg';
import imgWorkThisOut from './assets/images/SOUNDS_Work_This_Out.jpg';
import imgYoung2880 from './assets/images/SOUNDS_Young-2@0,75x.jpg';
import imgYoung960 from './assets/images/SOUNDS_Young-3@0,25x.jpg';
import imgYoung384 from './assets/images/SOUNDS_Young-4@0,1x.jpg';
import imgYoung from './assets/images/SOUNDS_Young.jpg';
import { ReactComponent as Amazon } from './assets/images/icons/stream-amazon.svg';
import { ReactComponent as Apple } from './assets/images/icons/stream-apple.svg';
import { ReactComponent as Deezer } from './assets/images/icons/stream-deezer.svg';
import { ReactComponent as Download } from './assets/images/icons/stream-download.svg';
import { ReactComponent as iTunes } from './assets/images/icons/stream-itunes.svg';
import { ReactComponent as MusicVideo } from './assets/images/icons/stream-musicvideo.svg';
import { ReactComponent as SoundCloud } from './assets/images/icons/stream-soundcloud.svg';
import { ReactComponent as Spotify } from './assets/images/icons/stream-spotify.svg';
import { ReactComponent as Tidal } from './assets/images/icons/stream-tidal.svg';
import { ReactComponent as YouTube } from './assets/images/icons/stream-youtube.svg';

const services = [
    {
        id: 0,
        slug: 'spotify',
        name: 'Spotify',
        icon: Spotify,
    },
    {
        id: 1,
        slug: 'apple-music',
        name: 'Apple Music',
        icon: Apple,
    },
    {
        id: 2,
        slug: 'itunes',
        name: 'iTunes',
        icon: iTunes,
    },
    {
        id: 3,
        slug: 'deezer',
        name: 'Deezer',
        icon: Deezer,
    },
    {
        id: 4,
        slug: 'tidal',
        name: 'Tidal',
        icon: Tidal,
    },
    {
        id: 5,
        slug: 'soundcloud',
        name: 'SoundCloud',
        icon: SoundCloud,
    },
    {
        id: 6,
        slug: 'youtube-music',
        name: 'YouTube Music',
        icon: YouTube,
    },
    {
        id: 7,
        slug: 'amazon',
        name: 'Amazon',
        icon: Amazon,
    },
    {
        id: 8,
        slug: 'download',
        name: 'Download',
        icon: Download,
    },
    {
        id: 9,
        slug: 'musicvideo',
        name: 'Music Video',
        icon: MusicVideo,
    },
];

const albums = [
    {
        id: 0,
        image: imgApex,
        images: [
            { src: imgApex384, width: 384 },
            { src: imgApex960, width: 960 },
            { src: imgApex1920, width: 1920 },
            { src: imgApex2880, width: 2880 },
            { src: imgApex },
        ],
        spotifyId: '4BdTczcWV48c5zdP6jtcgU',
        title: 'Apex 2019',
        slug: 'apex',
        description:
            'Apex 2019 was created for a big partybus in Norway. The track was signed by the label Project Influx.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/4BdTczcWV48c5zdP6jtcgU?si=4f48e234420245a4',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/album/apex-2019-single/1458572437',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/album/apex-2019-single/1458572437',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/92283972',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/track/106944006?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/apex-2019',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: 'https://music.youtube.com/watch?v=91rW2A0ToxU',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B07Q612CS7',
            },
            {
                id: 8,
                slug: 'download',
                url: 'https://www.toneden.io/musicbysounds/post/apex-2019',
            },
            {
                id: 9,
                slug: 'musicvideo',
                url: 'https://youtu.be/fwXnX54sDtw',
            },
        ],
    },
    {
        id: 1,
        image: imgOutOfBounds,
        images: [
            { src: imgOutOfBounds384, width: 384 },
            { src: imgOutOfBounds960, width: 960 },
            { src: imgOutOfBounds1920, width: 1920 },
            { src: imgOutOfBounds2880, width: 2880 },
            { src: imgOutOfBounds },
        ],
        spotifyId: '05VoUJ4d01c0tZCXHM60jQ',
        title: 'Out of Bounds',
        slug: 'out-of-bounds',
        description:
            '“Out of Bounds” by the Norwegian duo SOUNDS and the American vocalist Casey Breves tells a love story. SOUNDS discovered Casey on YouTube. The track was release under the label Jupita 25th October, 2019.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/05VoUJ4d01c0tZCXHM60jQ?si=035582b7a7384167',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/no/album/out-of-bounds-feat-casey-breves/1483604653',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/no/album/out-of-bounds-feat-casey-breves/1483604653',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/114984182',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/track/120374574?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/out-of-bounds',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: 'https://music.youtube.com/watch?v=SJ8NN4m3yhk',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B07Z5CBT7T',
            },
            {
                id: 8,
                slug: 'download',
                url: 'https://www.toneden.io/musicbysounds/post/out-of-bounds-feat-casey-breves',
            },
            {
                id: 9,
                slug: 'musicvideo',
                url: 'https://youtu.be/ixomNKOkBdk',
            },
        ],
    },
    {
        id: 2,
        image: imgOutOfBoundsRemix,
        images: [
            { src: imgOutOfBoundsRemix384, width: 384 },
            { src: imgOutOfBoundsRemix960, width: 960 },
            { src: imgOutOfBoundsRemix1920, width: 1920 },
            { src: imgOutOfBoundsRemix2880, width: 2880 },
            { src: imgOutOfBoundsRemix },
        ],
        spotifyId: '0fjicwrLfVuMZHKGJPBdUU',
        title: 'Out of Bounds (SOUNDS & LNGN Remix)',
        slug: 'out-of-bounds-remix',
        description:
            'Together with the talented producer LNGN, SOUNDS created a Deep House remix of their own release Out of Bounds (feat. Casey Breves). The track was released under the label Jupita 31th January, 2020.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/0fjicwrLfVuMZHKGJPBdUU?si=BOA2NUukRiyAIex2lByTuA',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/album/out-of-bounds-feat-casey-breves-s-o-u-n-d-s-lngn-remix/1493937006',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/album/out-of-bounds-feat-casey-breves-s-o-u-n-d-s-lngn-remix/1493937006',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/125532772',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/track/127459685?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/out-of-bounds-remix',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: '',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B083JMCBPF',
            },
            {
                id: 8,
                slug: 'download',
                url: 'https://www.toneden.io/musicbysounds/post/out-of-bounds-feat-casey-breves-s-o-u-n-d-s-lngn-remix',
            },
            {
                id: 9,
                slug: 'musicvideo',
                url: 'https://youtu.be/KBkkcQnf2IA',
            },
        ],
    },
    {
        id: 3,
        image: imgYoung,
        images: [
            { src: imgYoung384, width: 384 },
            { src: imgYoung960, width: 960 },
            { src: imgYoung1920, width: 1920 },
            { src: imgYoung2880, width: 2880 },
            { src: imgYoung },
        ],
        spotifyId: '0CuVINP25wTFXSUpmXWGCa',
        title: 'Young',
        slug: 'young',
        description:
            '“Young” by the Norwegian duo SOUNDS and the Canadian vocalist Brianna Corona tells a story about how millennial stress, time and aging affects lives. After SOUNDS discovered Brianna on YouTube, she went straight to a studio in Toronto and sent the vocals back to the duo in Norway. The track has regularly been played on radio in Norway (NRK mp3) and Australia (JOY). The track was released under the label 8D Tunes 6th Desember, 2019 and later transferred over to the label Jupita.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/6P670OxlgSeXcWXoR1xYGF?si=BM97ZL8_RUS4C_q3_AfKDQ',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/album/young/1493771910',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/album/young/1493771910',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/125529902',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/track/127457710?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/young',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: '',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B083JLPZ6V',
            },
            {
                id: 8,
                slug: 'download',
                url: 'https://www.toneden.io/musicbysounds/post/young-feat-brianna-corona',
            },
            {
                id: 9,
                slug: 'musicvideo',
                url: 'https://youtu.be/xK0kKbNXZc4',
            },
        ],
    },
    {
        id: 4,
        image: imgBackAround,
        images: [
            { src: imgBackAround384, width: 384 },
            { src: imgBackAround960, width: 960 },
            { src: imgBackAround1920, width: 1920 },
            { src: imgBackAround2880, width: 2880 },
            { src: imgBackAround },
        ],
        spotifyId: '2nhGSdtGMe8NCgKS3Am0dP',
        title: 'Back Around',
        slug: 'back-around',
        description:
            'The Norwegian duo SOUNDS completed “Back Around” earlier in 2019. The track combines Future House with commercial EDM and works both at the radio and in the club. The track was released under the label Jupita 14th February, 2020.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/2nhGSdtGMe8NCgKS3Am0dP?si=7APBTEt5QSyng9qjvrXS8g',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/album/back-around/1496629892',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/album/back-around/1496629892',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/128969812',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/album/129508017?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/back-around',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: 'https://music.youtube.com/watch?v=mnDcktMBl4Q',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B0849M3YB4',
            },
            {
                id: 8,
                slug: 'download',
                url: 'https://www.toneden.io/musicbysounds/post/back-around',
            },
            {
                id: 9,
                slug: 'musicvideo',
                url: 'https://youtu.be/nzYD49QfWSs',
            },
        ],
    },
    {
        id: 5,
        image: imgDTOLA,
        images: [
            { src: imgDTOLA384, width: 384 },
            { src: imgDTOLA960, width: 960 },
            { src: imgDTOLA1920, width: 1920 },
            { src: imgDTOLA2880, width: 2880 },
            { src: imgDTOLA },
        ],
        spotifyId: '3QKeJYS3ALV3zlX77hx9sz',
        title: "Don't Throw Our Love Away",
        slug: 'dtola',
        description:
            'Together with the promising vocalist Martin Novales from Los Angeles, the Norwegian duo SOUNDS is back with the track “Don\'t Throw Our Love Away”. Upon hearing the instrumental, Martin thought it was going to be a happy summer song but changed his mind after an argument with his girlfriend that resulted in her storming out in the middle of the night. "At this point I was tempted to chase after her but ended up expressing my feelings through this track." The track was released under the label Jupita 24th July, 2020.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/3QKeJYS3ALV3zlX77hx9sz?si=ebd15c7fa4db4017',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/album/dont-throw-our-love-away-feat-martin-novales/1521763122',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/album/dont-throw-our-love-away-feat-martin-novales/1521763122',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/158107942',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/track/147429253?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/dtola',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: 'https://music.youtube.com/watch?v=wL-xrG5hxKQ&list=RDAMVMwL-xrG5hxKQ',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B08CD2B1T3',
            },
            {
                id: 8,
                slug: 'download',
                url: 'https://www.toneden.io/musicbysounds/post/don-t-throw-our-love-away-feat-martin-novales',
            },
        ],
    },
    {
        id: 6,
        image: imgDreamingOutLoud,
        images: [
            { src: imgDreamingOutLoud384, width: 384 },
            { src: imgDreamingOutLoud960, width: 960 },
            { src: imgDreamingOutLoud1920, width: 1920 },
            { src: imgDreamingOutLoud2880, width: 2880 },
            { src: imgDreamingOutLoud },
        ],
        spotifyId: '1JJs7hvtQEfy8Vzqn7lq8i',
        title: 'Dreaming Out Loud',
        slug: 'dreaming-out-loud',
        description:
            'Dreaming Out Loud was released with the promising vocalist Martin Novales from Los Angeles. Martin describes the track like this: "Dreaming Out Loud is about a small kid, from a small town, who had some big dreams". It was released under the label Jupita 28th January, 2022.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/1JJs7hvtQEfy8Vzqn7lq8i?si=c76d298f4c074fae',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/album/dreaming-out-loud-single/1604836364',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/album/dreaming-out-loud-single/1604836364',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/287374862',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/track/212375206?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/dreaming-out-loud',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: 'https://music.youtube.com/watch?v=PNpFFIryvM8',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B09QD1VK8G',
            },
            {
                id: 8,
                slug: 'download',
                url: 'https://www.toneden.io/musicbysounds/post/dreaming-out-loud',
            },
        ],
    },
    {
        id: 7,
        image: imgWorkThisOut,
        images: [
            { src: imgWorkThisOut384, width: 384 },
            { src: imgWorkThisOut960, width: 960 },
            { src: imgWorkThisOut1920, width: 1920 },
            { src: imgWorkThisOut2880, width: 2880 },
            { src: imgWorkThisOut },
        ],
        spotifyId: '7JwDvF88Xje3UBVDUYJlzQ',
        title: 'Work This Out',
        slug: 'work-this-out',
        description:
            'Work This Out was released with the Irish vocalist Andy Asco. Andy has a unique voice and a great story teller, and is probably the next Ed Sheeran. The track is written and produced by Andy Asco, SOUNDS, Thomas John Warren, Rob Cohen, Reginald Theus and Stefan Hans Pajaro Van De Stadt. The guitar on the track is by Jørgen Konradsen. The track was released 9th September, 2022.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/7JwDvF88Xje3UBVDUYJlzQ?si=c76d298f4c074fae',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/us/album/work-this-out-single/1643167680',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/us/album/work-this-out-single/1643167680',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/track/1898284957',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/album/247628355?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/work-this-out',
            },
            {
                id: 6,
                slug: 'youtube-music',
                url: 'https://music.youtube.com/watch?v=W7cBAp_KbiM',
            },
            {
                id: 7,
                slug: 'amazon',
                url: 'https://music.amazon.com/albums/B0BCXCL3FR',
            },
        ],
    },
    {
        id: 8,
        image: imgWontLetYou,
        images: [
            { src: imgWontLetYou384, width: 384 },
            { src: imgWontLetYou960, width: 960 },
            { src: imgWontLetYou1920, width: 1920 },
            { src: imgWontLetYou2880, width: 2880 },
            { src: imgWontLetYou },
        ],
        spotifyId: '49zOtJcdm3zN65YDVx1q1E',
        title: "Won't Let You",
        slug: 'wont-let-you',
        description:
            'Won\'t Let You was released with the promising vocalist Martin Novales from Los Angeles. Martin describes the track like this: "Won\'t Let You is about overcoming past hurt to embrace the promise of a new beginning". The track is created by Martin Novales and SOUNDS, with Jørgen Konradsen on guitar. It was released 19th April, 2024.',
        services: [
            {
                id: 0,
                slug: 'spotify',
                url: 'https://open.spotify.com/track/49zOtJcdm3zN65YDVx1q1E?si=98a3e625636d4a94',
            },
            {
                id: 1,
                slug: 'apple-music',
                url: 'https://music.apple.com/album/wont-let-you/1738725673',
            },
            {
                id: 2,
                slug: 'itunes',
                url: 'https://music.apple.com/album/wont-let-you/1738725673',
            },
            {
                id: 3,
                slug: 'deezer',
                url: 'https://www.deezer.com/album/566738051',
            },
            {
                id: 4,
                slug: 'tidal',
                url: 'https://tidal.com/browse/album/354382904?play=true',
            },
            {
                id: 5,
                slug: 'soundcloud',
                url: 'https://soundcloud.com/musicbysounds/wont-let-you',
            },
            // {
            //     id: 6,
            //     slug: 'youtube-music',
            //     url: 'https://music.youtube.com/watch?v=PNpFFIryvM8',
            // },
            // {
            //     id: 7,
            //     slug: 'amazon',
            //     url: 'https://music.amazon.com/albums/B09QD1VK8G',
            // },
        ],
    },
];

const useStore = create((set) => ({
    albums,
    services,
}));

export default useStore;
