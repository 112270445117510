import './Navigation.scss';
import {
	Link
} from "react-router-dom";

function Navigation({onNavClick = null}) {
	const navItems = [
		{
			name: "home",
			slug: ""
		},
		{
			name: "music",
			slug: "music",
		},
		{
			name: "about",
			slug: "about",
		},
		{
			name: "contact",
			slug: "contact",
		},
	];

	return (
		<nav className="Navigation">
			<ul className="Navigation__list">
				{ navItems.map((item, key) => {
					return (
						<li className="Navigation__item" key={key}>
							<Link to={`/${item.slug}`} onClick={ (e) => onNavClick(e) }>{item.name}</Link>
						</li>
					);
				})}
			</ul>
		</nav>
	);
  }

  export default Navigation;
