import './ButtonGroup.scss';

function ButtonGroup({
	align = "left",
	children
}) {
	return (
		<div className={`ButtonGroup ${align}`}>
			<div className="ButtonGroup__item">
				{children}
			</div>
		</div>
	);
  }

  export default ButtonGroup;
