function Img({ src, srcset = [], ...props }) {
	if(!srcset.length) return <img src={src} {...props} />;

	return (
		<img
			sizes="(max-width: 2880px) 100vw, 2880px"
			srcset={`${srcset.map((set, key) => `${set.src} ${key < srcset.length - 1 ? set.width + "w" : ""}	`).join()}`}
			src={src}
			{...props}
		/>
	);
}

export default Img;
