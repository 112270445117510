import AnimatedText from '../AnimatedText/AnimatedText';
import MetaTags from '../MetaTags/MetaTags';
import './ContactPage.scss';

function ContactPage() {
	return (
		<>
			<MetaTags
				metaTitle = "Contact SOUNDS | Book the duo for an event or just reach out"
				metaDescription = "Contact SOUNDS for concert bookings or other inquiries. Anders and Johann also do collaborations and produce for other artists. Feel free to ask any questions."
				breadcrumbs={[{name: "Home", path: "/"}, {name: "Contact", path: "/contact"}]}
			/>

			<div className="ContactPage">
				<h1 className="ContactPage__heading"><AnimatedText>Contact</AnimatedText></h1>
				<div className="wrapper">
					<p className="ContactPage__link">Booking: <a href="mailto:booking@musicbysounds.com">booking@musicbysounds.com</a></p>
					<p className="ContactPage__link">General inquiries: <a href="mailto:contact@musicbysounds.com">contact@musicbysounds.com</a></p>
				</div>
			</div>
		</>
	);
}

export default ContactPage;
