import './SomeIcons.scss';
import { ReactComponent as FB } from'../../../assets/images/icons/facebook.svg';
import { ReactComponent as IG } from'../../../assets/images/icons/instagram.svg';
import { ReactComponent as SC } from'../../../assets/images/icons/soundcloud.svg';
import { ReactComponent as Spotify } from'../../../assets/images/icons/spotify.svg';

function SomeIcons() {
	const socials = [
		{
			slug: "facebook",
			url: "https://www.facebook.com/musicbysounds",
			component: <FB />
		},
		{
			slug: "instagram",
			url: "https://www.instagram.com/musicbysounds/",
			component: <IG />
		},
		{
			slug: "spotify",
			url: "https://open.spotify.com/artist/0hV83OXUaNLE05G8TlpMoP?si=WppbqX0uRyOTpOSN0piB3g",
			component: <Spotify />
		},
		{
			slug: "soundcloud",
			url: "https://soundcloud.com/musicbysounds",
			component: <SC />
		},
	];

	return (
		<nav className="SomeIcons">
			<ul className="SomeIcons__list">
				{ socials.map((social) => {
					return (
						<li className={`SomeIcons__item ${social.slug}`} key={social.slug}>
							<a href={social.url} target="_blank">
								{social.component}
							</a>
						</li>
					);
				})}
			</ul>
		</nav>
	);
  }

  export default SomeIcons;
