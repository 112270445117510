import Button from '../Button/Button';
import ButtonGroup from '../Button/ButtonGroup';
import Img from '../Img/Img';
import './MusicScene.scss';

function MusicScene({ image, images, link, spotifyId, title, description }) {
    return (
        <div className="MusicScene">
            <div className="MusicScene__cover">
                <iframe
                    loading="lazy"
                    src={`https://open.spotify.com/embed/track/${spotifyId}`}
                    data-mce-fragment="1"
                    width="330"
                    height="80"
                    frameborder="0"
                ></iframe>
                <div className="MusicScene__image">
                    <Img
                        src={image}
                        srcset={images}
                        alt={`Album cover of the track ${title} by SOUNDS`}
                    />
                </div>
            </div>
            <div className="MusicScene__content-wrapper">
                <div className="MusicScene__content">
                    <h2 className="MusicScene__heading">{title}</h2>
                    <p>{description}</p>
                    <ButtonGroup align="right">
                        <Button link={link}>Stream</Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
}

export default MusicScene;
