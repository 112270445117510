import Img from '../Img/Img';
import './MusicAlbum.scss';

function MusicAlbum({image, images, active, title}) {
	return (
		<div className={`MusicAlbum ${active ? "active" : ""}`}>
			<div className="MusicAlbum__image">
				<Img src={image} srcset={images} alt={`Album cover of the track ${title} by SOUNDS`} />
				<div className="MusicAlbum__spotlight"></div>
			</div>
			<h2 className="MusicAlbum__heading">{title}</h2>
		</div>
	)
}

export default MusicAlbum;
