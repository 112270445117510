import { Redirect, useParams } from "react-router-dom";
import useStore from '../../store';

function SingleMusicPageRedirect() {
	const { slug } = useParams();
	const track = useStore(state => state.albums.find(album => album.slug === slug));

	return track ? <Redirect to={`/music/${slug}`} /> : <Redirect to="/" />;
}

export default SingleMusicPageRedirect;
