import './MusicPage.scss';
import { useState } from 'react';
import MusicAlbum from './MusicAlbum';
import MusicScene from './MusicScene';
import useStore from '../../store';
import AnimatedText from '../AnimatedText/AnimatedText';
import { Link } from 'react-router-dom';
import MetaTags from '../MetaTags/MetaTags';

function MusicPage() {
	const data = useStore(state => state.albums.sort((a, b) => {
		if(a.id < b.id) return 1;
		return -1;
	}));

	const [sceneAlbum, setSceneAlbum] = useState(data[0]);

	return (
		<>
			<MetaTags
				metaTitle = "SOUNDS' music | Listen to releases produced by the artists"
				metaDescription = "Stream SOUNDS' latest songs. The duo has radio hits within pop and EDM and have created tracks with people like Brianna Corona, Martin Novales and Casey Breves."
				breadcrumbs={[{name: "Home", path: "/"}, {name: "Music", path: "/music"}]}
			/>

			<div className="MusicPage">
				<div className="wrapper full">
					<h1 className="MusicPage__heading"><AnimatedText>Music</AnimatedText></h1>
					<div className="MusicPage__wrapper">
						<div className="MusicPage__slider">
							<div className="MusicPage__list">
								{data.map((album, key) => (
									<div className="MusicPage__list-item" onClick={() => setSceneAlbum(album)}>
										<Link className="MusicAlbum__mobile-link" to={`/music/${album.slug}`}></Link>
										<MusicAlbum title={album.title} image={album.image} images={album.images} active={sceneAlbum && album.id === sceneAlbum.id} />
									</div>
								))}
							</div>
						</div>
						<div className="MusicPage__scene">
							{sceneAlbum &&
							<MusicScene
								title={sceneAlbum.title}
								description={sceneAlbum.description}
								image={sceneAlbum.image}
								images={sceneAlbum.images}
								link={`/music/${sceneAlbum.slug}`}
								spotifyId={sceneAlbum.spotifyId}
							/>}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default MusicPage;
