import './AboutPage.scss';
import Logo from '../Logo/Logo';
import mainImage from "../../assets/images/sounds_main.jpg";
import mainImage1920 from "../../assets/images/sounds_main-1@0,5x.jpg";
import mainImage2880 from "../../assets/images/sounds_main-2@0,75x.jpg";
import mainImage1080 from "../../assets/images/sounds_main-3@0,25x.jpg";
import mainImage600 from "../../assets/images/sounds_main-4@0,1x.jpg";
import kidJohannImage from "../../assets/images/kid_johann.jpg";
import kidJohannImage1920 from "../../assets/images/kid_johann-1@0,5x.jpg";
import kidJohannImage1080 from "../../assets/images/kid_johann-3@0,25x.jpg";
import kidJohannImage600 from "../../assets/images/kid_johann-4@0,1x.jpg";
import kidAndersImage from "../../assets/images/kid_anders.jpg";
import kidAndersImage1080 from "../../assets/images/kid_anders-3@0,25x.jpg";
import kidAndersImage600 from "../../assets/images/kid_anders-4@0,1x.jpg";
import bachelorImage from "../../assets/images/anders_johann_bachelor.jpg";
import bachelorImage1080 from "../../assets/images/anders_johann_bachelor-3@0,25x.jpg";
import bachelorImage600 from "../../assets/images/anders_johann_bachelor-4@0,1x.jpg";
import palsImage from "../../assets/images/real_pals.jpg";
import palsImage1920 from "../../assets/images/real_pals-1@0,5x.jpg";
import palsImage2880 from "../../assets/images/real_pals-2@0,75x.jpg";
import palsImage1080 from "../../assets/images/real_pals-3@0,25x.jpg";
import palsImage600 from "../../assets/images/real_pals-4@0,1x.jpg";
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SomeIcons from '../Header/SomeIcons/SomeIcons';
import AboutPageImage from './AboutPageImage';
import Button from '../Button/Button';
import ButtonGroup from '../Button/ButtonGroup';
import MetaTags from '../MetaTags/MetaTags';

function AboutPage() {
	const [scrollTop, setScrollTop] = useState(0);
	const [aboutHeight, setAboutHeight] = useState(0);
	const headingEl = useRef(null);
	const logoEl = useRef(null);
	const aboutPage = useRef(null);
	const firstSection = useRef(null);
	const mainParallaxElement = useRef(null);
	const palsParallaxElement = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			headingEl.current?.classList.remove("hidden");
			logoEl.current?.classList.remove("hidden");
		}, 100);

		setAboutHeight(aboutPage.current.offsetHeight);
	}, [])

	const onAboutPageScroll = (e) => {
		setScrollTop(e.target.scrollTop);
	}

	/**
	 * Get an elements offset position on page
	 *
	 * @param   {HTMLElement}  el  HTML element to get position of
	 *
	 * @return  {Object}           Object containing top and left position of element
	 */
	function getOffset(el) {
		var _x = 0;
		var _y = 0;
		while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
			if(el === aboutPage.current) break; // break out if parent element is element that has the main scrolling on the page
			_x += el.offsetLeft - el.scrollLeft;
			_y += el.offsetTop - el.scrollTop;
			el = el.offsetParent;
		}
		return { top: _y, left: _x };
	}

	return (
		<>
			<MetaTags
				metaTitle = "About SOUNDS | Who is behind the duo? What is their history?"
				metaDescription = "SOUNDS produce music and is an artist duo by Anders and Johann. The Norwegians' genre is pop/EDM. Read about how they met and why they chose the name SOUNDS."
				breadcrumbs={[{name: "Home", path: "/"}, {name: "About", path: "/about"}]}
				isArticle
				articleHeadline="About SOUNDS"
			/>

			<div className="AboutPage" onScroll={ onAboutPageScroll } ref={aboutPage}>
				<section className="AboutPage__section center">
					<div className="wrapper">
						<h1 className="AboutPage__heading hidden" ref={headingEl}>About <span className="logo hidden" ref={logoEl}><Logo /></span></h1>
						<div className="AboutPage__start-arrow" onClick={() => {
							firstSection.current?.scrollIntoView({
								behavior: "smooth",
								block: "nearest"
							});
						}}></div>
					</div>
				</section>
				<section ref={firstSection} className="AboutPage__section">
					<div className="wrapper very-wide">
						<div className="AboutPage__small-image-text">
							<div className="AboutPage__small-image-text__image">
								<AboutPageImage src={ kidAndersImage } srcset={[
									{ src: kidAndersImage600, width: 300 },
									{ src: kidAndersImage1080, width: 680 },
									{ src: kidAndersImage },
								]} alt={ "Anders just a few years sitting amoung instruments." } scrollBottom={ scrollTop + aboutHeight } parallaxRange={ [-200, 200] } scrollContainer={ aboutPage.current } />
							</div>
							<div className="AboutPage__small-image-text__text">
								<h2>From drums to computers</h2>
								<p>You know how they say you need to learn how to walk before you can run? Well, in the case of Anders, he knew how to play drums before he could even wipe himself. Already at the age of five, the Norwegian drummer refused to go to his kindergarden in the city of Halden without his drumsticks. So, by theage of eight Anders knew how to play guitar, bass and piano.</p>
								<p>By the age of 12, Anders had already been in three different bands and played more than 20 concerts. Luckily for me, he did not settle with that, and at this stage, Anders realized that he could compose entire tracks on a simple computer. Endless opportunites showed itself for the little boy who once just thought he could run, but now discovered he could fly on his own...</p>
							</div>
						</div>
					</div>
				</section>
				<section className="AboutPage__section">
					<div className="wrapper very-wide">
						<div className="AboutPage__small-image-text image-right">
							<div className="AboutPage__small-image-text__image">
								<AboutPageImage src={ kidJohannImage } alt={ "Johann just a few years old wearing a headset." } scrollBottom={ scrollTop + aboutHeight } parallaxRange={ [-200, 200] } scrollContainer={ aboutPage.current } srcset={[
									{ src: kidJohannImage600, width: 300 },
									{ src: kidJohannImage1080, width: 680 },
									{ src: kidJohannImage1920, width: 1420 },
									{ src: kidJohannImage },
								]} />
							</div>
							<div className="AboutPage__small-image-text__text">
								<h2>Meanwhile in Trondheim</h2>
								<p>While Anders learned how to play drums, I, Johann, literally learned how to wipe myself in Norway's acient capital, Trondheim. Don't think for a second that Anders and I are alike. This photo of me looking like a 3 year old DJ is a lie. Honestly, my mom knew how to play piano, but I didn't touch one until I was like 16.</p>
								<p>However, I did actually sing in a band when I was 8 years old. I'd like to say that the band had a great impact on my music career, but I can't say it did (not yet at least). And yes, I could mention that I also played drums from the age of 10-12, but at every performance the teacher made me do the bongo drum, while some of the more "talented" players could play the real drum. And of course, I still hold grudges about that today. Look at me now, Johnny!</p>
							</div>
						</div>
					</div>
				</section>

				<section ref={mainParallaxElement} className="AboutPage__section edge-fade">
					<div className="AboutPage__full-image">
						<AboutPageImage src={ mainImage } srcset={[
									// { src: mainImage600, width: 300 },
									// { src: mainImage1080, width: 600 },
									{ src: mainImage1920, width: 600 },
									{ src: mainImage2880, width: 905 },
									{ src: mainImage },
								]} alt={ "Anders and Johann in front of a stone wall." } scrollBottom={ scrollTop + aboutHeight } parallaxRange={ [300, -300] } zoom={2.3} shouldFill scrollContainer={ aboutPage.current } />
					</div>
					<div className={`AboutPage__pointers ${scrollTop + aboutHeight * .2 >= getOffset(mainParallaxElement?.current).top && scrollTop < getOffset(mainParallaxElement?.current).top + aboutHeight * .3 ? "show" : ""}`}>
						<div className="AboutPage__pointer" data-text="Johann"></div>
						<div className="AboutPage__pointer" data-text="Anders"></div>
					</div>
					<div className={`AboutPage__box tight ${scrollTop + aboutHeight * .6 >= getOffset(mainParallaxElement?.current).top && scrollTop < getOffset(mainParallaxElement?.current).top + mainParallaxElement?.current?.offsetHeight * .6 ? "show" : ""}`}>
						<div className="wrapper">
							<h2>How it all came together</h2>
							<p>In 2016, Anders and I met for the first time. Both of us had for some reason chosen to study in Fredrikstad, a random city in Norway with bad bus connections. We didn't really talk much the first days to be honest, but then we both got to know that we produced music we decided that we could try to meet up for a session.</p>
							<p>At first, I don't think Anders was that impressed by me, but I liked the guy and wanted to work more with him. I understood that I had to step up my game though, so I sat down and created the best track I could before I sent it over to him. And just like that, Anders was sold and we became a duo.</p>
						</div>
					</div>
				</section>
				<section className="AboutPage__section">
					<div className="AboutPage__image-text">
						<div className="AboutPage__image-text__image">
							<AboutPageImage src={ bachelorImage } srcset={[
									{ src: bachelorImage600, width: 400 },
									{ src: bachelorImage1080, width: 900 },
									{ src: bachelorImage },
								]} zoom={2} alt={ "Anders and Johann together on a pallet in a the water." } scrollBottom={ scrollTop + aboutHeight } shouldFill parallaxRange={ [-120, 120] } scrollContainer={ aboutPage.current } />
						</div>
						<div className="wrapper">
							<h2>The origin of SOUNDS</h2>
							<p>In 2019, we agreed that it would be interesting to see how high the two of us could fly, but we were lacking a good artist name. We wanted something that was easy to remember while it also described what we were doing, and ended up with SOUNDS (stylized S O U N D S). I know, everyone that makes music is literally working with sounds, but so do we. One thing that makes SOUNDS unique is that we never limit ourself to a specific genre, which means we need to have a lot of different sounds ready in our pockets. And we think it's a freakin' cool name, so just leave it!</p>
						</div>
					</div>
				</section>
				<section ref={palsParallaxElement} className="AboutPage__section edge-fade">
					<div className="AboutPage__full-image">
						<AboutPageImage src={ palsImage } srcset={[
									// { src: palsImage600, width: 100 },
									// { src: palsImage1080, width: 600 },
									{ src: palsImage1920, width: 600 },
									{ src: palsImage2880, width: 905 },
									{ src: palsImage },
								]} zoom={2.8} alt={ "Anders and Johann sitting on each of their pallet with a stone background behind them." } scrollBottom={ scrollTop + aboutHeight } parallaxRange={ [300, -300] } shouldFill scrollContainer={ aboutPage.current } />
					</div>
					<div className={`AboutPage__box ${scrollTop + aboutHeight * .6 >= getOffset(palsParallaxElement?.current).top && scrollTop < getOffset(palsParallaxElement?.current).top + palsParallaxElement?.current?.offsetHeight * .6 ? "show" : ""}`}>
						<div className="wrapper">
							<h2>The journey of SOUNDS</h2>
							<p>After years with hard work we finally felt it paid of during the spring of 2020. A lot of stuff started happening: our song, <Link to="/music/young">Young</Link>, became the third most played track on the biggest EDM radio channel in Norway, we got hired to play as the only artist during the World Championship in Speedskating and as if that wasn't enough our track started hitting Spotify Playlists by huge artists like Martin Garrix and Nicky Romero. By the end of 2020 we had more than 100 000 monthly listeners on Spotify and the number of streams that came in immediately became more than what we ever imagined.</p>
							<p>Hearing this you might think that we only create EDM, club music or whatever you want to define it as, but that's not true. We don't really want to say that we have a spesific genre, but we must admit that recently and going forward we might touch in on music that some people may experience as pop or EDM, depending on who you ask.</p>
							<ButtonGroup align='right'>
								<Button link="/music">Our music</Button>
							</ButtonGroup>
						</div>
					</div>
				</section>
				<section className="AboutPage__section">
					<div className="wrapper">
						<h2>So what and who is SOUNDS</h2>
						<p>For those of you who still don't feel like you know SOUNDS or just wanted a short description, here it goes. SOUNDS is a duo consisting of Anders Jamissen and myself, Johann Vårvik. At the moment we touch in genres close to both pop and EDM, but we don't limit ourself to any genre really. We recommend that you check out our sounds right <Link to="/music">here</Link>, and if you feel like going completely crazy you could even consider to follow us at any of the platforms below, because going forward you can expect a lot of new sounds coming from SOUNDS. Sounds good?</p>
						<div className="right-content">
							<SomeIcons />
						</div>
					</div>

				</section>
			</div>
		</>
	);
}

export default AboutPage;
