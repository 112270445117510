import { useEffect, useRef, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import useStore from '../../store';
import Img from '../Img/Img';
import MetaTags from '../MetaTags/MetaTags';
import './SingleMusicPage.scss';
import StreamingService from './StreamingService';

function SingleMusicPage() {
    const { slug } = useParams();
    const content = useRef(null);
    const track = useStore((state) =>
        state.albums.find((album) => album.slug === slug)
    );
    const nextTrack = useStore((state) =>
        state.albums.find((album) => album.id === track?.id - 1)
    );
    const prevTrack = useStore((state) =>
        state.albums.find((album) => album.id === track?.id + 1)
    );

    const [contentScrollIsBottom, setContentScrollIsBottom] = useState(false);
    const [contentScrollIsTop, setContentScrollIsTop] = useState(true);

    useEffect(() => {
        return content ? onContentScroll(content.current) : null;
    }, []);

    const onContentScroll = (element) => {
        setContentScrollIsBottom(
            element?.scrollTop + element?.offsetHeight >=
                element?.scrollHeight - 20
        );
        setContentScrollIsTop(element?.scrollTop <= 20);
    };

    if (!track) return <Redirect to="/music" />;

    return (
        <>
            <MetaTags
                metaTitle={`S O U N D S - ${track?.title} | Listen to the song here`}
                metaDescription={track?.description}
                metaImage={track?.image}
                breadcrumbs={[
                    { name: 'Home', path: '/' },
                    { name: 'Music', path: '/music' },
                    { name: track?.title, path: `/music/${track?.slug}` },
                ]}
            />

            <div className="SingleMusicPage">
                <div className="SingleMusicPage__cover">
                    <iframe
                        className="SingleMusicPage__desktop-iframe"
                        loading="lazy"
                        src={`https://open.spotify.com/embed/track/${track?.spotifyId}`}
                        data-mce-fragment="1"
                        width="225"
                        height="80"
                        frameborder="0"
                    ></iframe>
                    <div className="SingleMusicPage__image">
                        <Img
                            src={track?.image}
                            srcset={track?.images}
                            alt={`Album cover of the track ${track?.title} by SOUNDS`}
                        />
                    </div>
                </div>
                <div
                    ref={content}
                    className={`SingleMusicPage__content`}
                    onScroll={(e) => onContentScroll(e.target)}
                >
                    <div
                        className={`SingleMusicPage__fades${
                            contentScrollIsBottom ? ' bottom' : ''
                        }${contentScrollIsTop ? ' top' : ''}`}
                    ></div>
                    <div className={`wrapper wide`}>
                        <div className="SingleMusicPage__main-content">
                            <Link
                                className="SingleMusicPage__link back"
                                to="/music"
                            >
                                All tracks
                            </Link>
                            <h1 className="SingleMusicPage__heading">
                                {track?.title}
                            </h1>
                            <div>
                                <iframe
                                    className="SingleMusicPage__mobile-iframe"
                                    loading="lazy"
                                    src={`https://open.spotify.com/embed/track/${track?.spotifyId}`}
                                    data-mce-fragment="1"
                                    width="225"
                                    height="80"
                                    frameborder="0"
                                ></iframe>
                                <p className="SingleMusicPage__description">
                                    {track?.description}
                                </p>
                            </div>
                            <hr className="SingleMusicPage__separator" />
                            <h2>Stream</h2>
                            <div className="streaming-list">
                                {track?.services?.map(
                                    (service) =>
                                        service.url && (
                                            <div className="streaming-list__item">
                                                <StreamingService
                                                    slug={service.slug}
                                                    id={`service-${service.id}`}
                                                    key={service.id}
                                                    url={service.url}
                                                    isVideo={
                                                        service.slug ===
                                                        'musicvideo'
                                                    }
                                                    isDownload={
                                                        service.slug ===
                                                        'download'
                                                    }
                                                />
                                            </div>
                                        )
                                )}
                            </div>
                            <div className="SingeMusicPage__track-links">
                                {prevTrack ? (
                                    <Link
                                        className={`SingleMusicPage__link prev`}
                                        to={`/music/${prevTrack?.slug}`}
                                    >
                                        Previous track
                                    </Link>
                                ) : (
                                    <div
                                        className={`SingleMusicPage__link prev disabled`}
                                    >
                                        Previous track
                                    </div>
                                )}
                                {nextTrack ? (
                                    <Link
                                        className={`SingleMusicPage__link next`}
                                        to={`/music/${nextTrack?.slug}`}
                                    >
                                        Next track
                                    </Link>
                                ) : (
                                    <div
                                        className={`SingleMusicPage__link next disabled`}
                                    >
                                        Next track
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleMusicPage;
