import Helmet from 'react-helmet/es/Helmet';
import metaImageFallback from '../../assets/images/sounds_main-1@0,5x.jpg';
import metaLogo from '../../assets/images/logo.jpg';
import useStore from '../../store';

function MetaTags({
		metaTitle = "SOUNDS | Music | musicbysounds | Pop/EDM by Johann and Anders",
		metaDescription = "SOUNDS produce music within pop/EDM. Get to know the artists Johann and Anders, or check out their latest tracks. The Norwegian duo is known for creating hits.",
		metaImage = metaImageFallback,
		breadcrumbs = false,
		isArticle = false,
		articleHeadline = "SOUNDS"
	}) {
		const tracks = useStore(state => state.albums);
		const metaUrl = `https://${window.location.host}${window.location.pathname}`;

	return (
		<Helmet>
			<title>{metaTitle}</title>
			<meta name="description" content={metaDescription}></meta>
			<link rel="canonical" href={metaUrl} />
			<meta property="og:locale" content="en_US" />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={metaTitle} />
			<meta property="og:description" content={metaDescription} />
			<meta property="og:url" content={metaUrl} />
			<meta property="og:site_name" content="SOUNDS" />
			<meta property="og:image" content={metaImage} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:description" content={metaDescription} />
			<meta name="twitter:title" content={metaTitle} />
			<meta name="twitter:image" content={metaImage} />
			<meta name="twitter:url" content={metaUrl} />
			``
			<script type="application/ld+json">
				{`
					{
						"@context":"https://schema.org",
						"@graph":[
							{
								"@type":"MusicGroup",
								"@id":"${metaUrl}#sounds",
								"genre":"Pop, EDM",
								"name":"SOUNDS",
								"url":"${metaUrl}",
								"image":"${metaImage}",
								"logo":{
									"@type":"ImageObject",
									"url":"${metaLogo}",
									"width":640,
									"height":266,
									"caption":"SOUNDS"
								},
								"track": [${tracks.map((track) => (`{
									"@type":"MusicRecording",
									"audio":"${track.services.find((service) => service.slug === 'spotify')?.url}",
									"name":"${track.title}",
									"url":"https://${window.location.host}/music/${track.slug}"
								}`))}]
							},
							{
								"@context": "https://schema.org",
								"@type": "Organization",
								"url":"${metaUrl}",
								"logo":{
									"@type":"ImageObject",
									"url":"${metaLogo}",
									"width":640,
									"height":266,
									"caption":"SOUNDS"
								}
							},
							{
								"@type":"WebSite",
								"@id":"${metaUrl}#website",
								"url":"${metaUrl}",
								"name":"SOUNDS",
								"description":${JSON.stringify(metaDescription)},
								"publisher":{
									"@id":"${metaUrl}#sounds"
								},
								"inLanguage":"en-US"
							},
							{
								"@type":"ImageObject",
								"@id":"${metaUrl}#image",
								"inLanguage":"en-US",
								"url":"${metaImage}"
							},
							{
								"@type":"WebPage",
								"url":"${metaUrl}",
								"name":${JSON.stringify(metaTitle)},
								"isPartOf":{
									"@id":"${metaUrl}#website"
								},
								"about":{
									"@id":"${metaUrl}#sounds"
								},
								"primaryImageOfPage":{
									"@id":"${metaUrl}#image"
								},
								"inLanguage":"en-US"
							},
							{
								"@type":"BreadcrumbList",
								"itemListElement":[${breadcrumbs ? breadcrumbs.map((crumb, key) => (`{
									"@type":"ListItem",
									"position":${key + 1},
									"name":"${crumb.name}",
									"item": "https://${window.location.host}${crumb.path}"
								}`)) : `{
									"@type":"ListItem",
									"position":1,
									"name":"Home"
								}`}]
							}
							${ isArticle ?
								`, {
									"@type": "Article",
									"headline": "${articleHeadline}",
									"image": "${metaImage}",
									"author": {
										"@id":"${metaUrl}#sounds"
									}
								}
								`
							: "" }
						]
					}
				`}
			</script>
		</Helmet>
	);
}

export default MetaTags;
